import { IC_audio, IC_Excel, IC_File, IC_img, IC_pdf, IC_power_point, IC_video, IC_word, } from '../assets/icons/files';
export var getFileType = function (mimetype, filename) {
    if (filename === void 0) { filename = ''; }
    var ext;
    if (mimetype) {
        ext = mimetype.split('/')[0];
    }
    else {
        ext = filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();
    }
    switch (ext) {
        case 'jpg':
        case 'jpeg':
        case 'jfif':
        case 'png':
        case 'gif':
        case 'bmp':
        case 'svg':
        case 'image':
            return 'image';
        case 'mp3':
        case 'wav':
        case 'ogg':
        case 'm4a':
            return 'audio';
        case 'mp4':
        case 'avi':
        case 'webm':
        case 'wmv':
        case 'flv':
            return 'video';
        case 'pdf':
        case 'application':
            return 'pdf';
        case 'doc':
        case 'docx':
            return 'word';
        case 'xls':
        case 'xlsx':
            return 'excel';
        case 'ppt':
        case 'pptx':
            return 'powerpoint';
        default:
            return 'unknown';
    }
};
export var getFileIcon = function (type) {
    switch (type) {
        case 'image':
            return IC_img;
        case 'audio':
            return IC_audio;
        case 'video':
            return IC_video;
        case 'pdf':
            return IC_pdf;
        case 'word':
            return IC_word;
        case 'powerpoint':
            return IC_power_point;
        case 'excel':
            return IC_Excel;
        default:
            return IC_File;
    }
};
export function getReadableFileSizeString(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes /= 1024;
        i += 1;
    } while (fileSizeInBytes > 1024);
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}
export function setFormData(files, mentions, uploadPropertyName) {
    if (uploadPropertyName === void 0) { uploadPropertyName = 'files[]'; }
    var formData = new FormData();
    files.forEach(function (file) {
        formData.append(uploadPropertyName, file);
    });
    mentions === null || mentions === void 0 ? void 0 : mentions.forEach(function (mention) {
        formData.append("mentions[]", mention);
    });
    return formData;
}
